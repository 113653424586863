<template>
    <b-modal size="md"
             centered
             hide-header
             hide-footer
             content-class="shadow-sm border-0 rounded-lg"
             v-bind:visible="value"
             v-on:hide="hideModal"
             v-on:cancel="hideModal"
             v-on:close="hideModal">
        <div class="title position-sticky d-flex justify-content-between align-items-center mb-4">
            <h1 class="font-weight-bolder">
                Data Stores
            </h1>
            <div>
                <button class="btn btn-lg btn-outline-dark border-0 mr-1"
                        v-on:click.prevent.stop="hideModal">
                    <font-awesome-icon icon="times"></font-awesome-icon>
                </button>
            </div>
        </div>

        <div class="text-right mb-3">

        </div>

        <ul class="list-group shadow-sm">
            <li class="list-group-item d-flex"
                v-for="{ name } in stores"
                v-bind:key="name">
                <div style="flex: 4;"
                     v-bind:class="{ 'font-weight-bolder': name === current }">
                    {{ name }}
                </div>
                <div class="text-right"
                     style="flex: 2">
                    <span class="badge badge-primary"
                          v-if="name === current">
                        {{ name === current ? "Current" : "" }}
                    </span>
                    <button class="btn btn-sm btn-light border px-3"
                            v-else
                            v-on:click="openStore(name)">
                        📂 Open
                    </button>
                </div>
            </li>
            <li class="list-group-item d-flex px-2 py-2">
                <div class="flex-grow-1 pr-2">
                    <b-form-input size="sm"
                                  placeholder="Enter a store name"
                                  v-model="storeName">
                    </b-form-input>
                </div>
                <div>
                    <button class="btn btn-sm btn-light border px-3"
                            v-on:click="openStore(storeName); storeName = ''">
                        📂 Open
                    </button>
                </div>
            </li>
        </ul>
    </b-modal>
</template>

<script>
import {
    BModal,
    BFormInput,
} from "bootstrap-vue";

export default {
    name: "ModalDataStore",
    components: {
        BModal,
        BFormInput,
    },
    props: {
        "value": {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            storeName: "",
        };
    },
    computed: {
        current () {
            return this.$store.getters["data/currentStore"];
        },
        stores () {
            return this.$store.getters["data/stores"];
        },
    },
    methods: {
        hideModal () {
            this.$emit("input", false);
        },
        async openStore (name) {
            await this.$store.dispatch(
                "data/open",
                name
            );
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
