<template>
    <b-modal size="md"
             centered
             hide-header
             hide-footer
             content-class="shadow-sm border-0 rounded-lg"
             v-bind:visible="value"
             v-on:hide="hideModal"
             v-on:cancel="hideModal"
             v-on:close="hideModal">
        <div class="position-sticky title d-flex justify-content-between align-items-center mb-4">
            <h1 class="font-weight-bolder mb-0">
                Change Password
            </h1>
            <div>
                <button class="btn btn-lg btn-outline-dark border-0 mr-1"
                        v-on:click.prevent.stop="hideModal">
                    <font-awesome-icon icon="times"></font-awesome-icon>
                </button>
            </div>
        </div>


    </b-modal>
</template>

<script>
import {
    BModal,
    BFormGroup,
    BFormInput,
} from "bootstrap-vue";

export default {
    name: "ModalChangePassword",
    components: {
        BModal,
        BFormGroup,
        BFormInput,
    },
    props: {
        "value": {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {

        };
    },
    computed: {
        user () {
            return this.$store.state.user.user;
        },
        userRole () {
            return this.$store.state.user.user.role;
        },
    },
    methods: {
        hideModal () {
            this.$emit("input", false);
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
