<template>
    <b-modal size="md"
             centered
             hide-header
             hide-footer
             content-class="shadow-sm border-0 rounded-lg"
             v-bind:visible="value"
             v-on:hide="hideModal"
             v-on:cancel="hideModal">
        <h1 class="font-weight-bolder mb-4">
            Reauthenticate
        </h1>

        <slot></slot>

        <div class="form-row">
            <b-form-group class="col-md-12"
                          label-for="challenge"
                          label="Password"
                          invalid-feedback="Incorrect credential. "
                          v-bind:state="challengeValid">
                <b-input-group>
                    <b-form-input id="challenge"
                                  class=""
                                  required
                                  type="password"
                                  v-bind:state="challengeValid"
                                  v-model="challengeReply"
                                  v-on:focus="challengeValid = challengeValid === false ? null : challengeValid">
                    </b-form-input>
                    <b-input-group-append>
                        <button class="btn btn-danger"
                                v-bind:class="{ 'btn-loading': isLoading }"
                                v-bind:disabled="!allowConfirm"
                                v-on:click="confirm">
                            Confirm
                        </button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
        </div>
    </b-modal>
</template>

<script>
import {
    BModal,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
} from "bootstrap-vue";

import {
    DefaultLogin,
} from "../../config";

export default {
    name: "ModalReAuthenticate",
    components: {
        BModal,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupAppend,
    },
    props: {
        "value": {
            type: Boolean,
        },
        "username": {
            type: String,
            required: true,
        },
        "callback": {
            type: Function,
            required: true,
        }
    },
    data () {
        return {
            isLoading: false,

            challengeValid: null,
            challengeReply: "",
        };
    },
    computed: {
        allowConfirm () {
            return !!this.challengeReply;
        },
    },
    methods: {
        hideModal () {
            this.$emit("input", false);
        },
        reset () {
            this.challengeReply = "";
        },
        async confirm () {
            this.isLoading = true;

            if (
                DefaultLogin.username !== this.username ||
                DefaultLogin.password !== this.challengeReply
            ) {
                this.isLoading = false;
                this.challengeValid = false;
                this.challengeReply = "";
                return;
            }

            this.challengeValid = true;
            this.isLoading = false;
            if (typeof this.callback === "function") {
                this.callback();
            }
            this.reset();
            this.hideModal();
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
