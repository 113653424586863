<template>
    <section class="settings container-fluid p-3 h-100">
        <div class="position-sticky title d-flex">
            <div class="d-flex d-xl-none align-items-center">
                <button-toggle-side-bar></button-toggle-side-bar>
            </div>
            <h1 class="d-none d-lg-block display-4 font-weight-bolder text-nowrap px-3 pb-2 mb-0">
                Settings
            </h1>
            <h1 class="d-block d-lg-none font-weight-bolder text-nowrap px-3 pb-2 mb-0">
                Settings
            </h1>
        </div>
        <div class="row m-md-0">
            <div class="col-md-6 py-3">
                <h5 class="card-title">
                    Account
                </h5>
                <button class="btn btn-block btn-dark"
                        v-on:click="isModalChangePasswordOpen = true">
                    Change Password
                </button>
            </div>
            <div class="col-md-6 py-3">
                <div class="card border-danger shadow-sm">
                    <div class="card-body">
                        <h5 class="card-title">
                            ☠️ Danger Zone
                        </h5>
                        <button class="btn btn-block btn-danger"
                                v-on:click="isModalReAuthenticateOpen = true">
                            Wipe Data
                        </button>
                    </div>
                </div>

            </div>
        </div>


        <modal-change-password v-model="isModalChangePasswordOpen"></modal-change-password>
        <modal-re-authenticate username="tester@test.com"
                               v-bind:callback="openModalDataStore"
                               v-model="isModalReAuthenticateOpen">
        </modal-re-authenticate>
        <modal-data-store v-model="isModalDataStoreOpen"></modal-data-store>
    </section>
</template>

<script>
import ButtonToggleSideBar from "../components/layout/ButtonToggleSideBar";
import ModalDataStore from "../components/Settings/ModalDataStore";
import ModalReAuthenticate from "../components/modals/ModalReAuthenticate";
import ModalChangePassword from "../components/Settings/ModalChangePassword";

export default {
    name: "Settings",
    components: {
        ModalChangePassword,
        ModalReAuthenticate,
        ModalDataStore,
        ButtonToggleSideBar,
    },
    metaInfo: {
        title: "Settings",
    },
    data () {
        return {
            isModalChangePasswordOpen: false,
            isModalReAuthenticateOpen: false,
            isModalDataStoreOpen: false,
        };
    },
    computed: {

    },
    methods: {
        openModalDataStore () {
            this.isModalDataStoreOpen = true;
        },
    },
}
</script>

<style lang="scss" scoped>

</style>
